import { cloneElement, useState } from "react";

import MainNavbar from "../components/MainNavbar";
import MainFooter from "../components/MainFooter";
import { Languages } from "utils/datautils";
import { useRouter } from "next/router";

type MainProps = {
  children: JSX.Element
}

/**
 * @param language
 * @param requestDemo
 * used to control Request Demo Modal in props.children such as
 * index.ts (Home).
 * consider using useReducer if it starts to get too complicated
 */
export default function MainLayout(props: MainProps) {
  const [language, setLanguage] = useState(Languages.Bahasa);
  const [requestDemo, setRequestDemo] = useState(false);
  const router = useRouter();
  const { locale } = router;

  return (
    <>
      <MainNavbar
        lang={language} 
        onLangChange={(lang: Languages) => setLanguage(locale === 'id' ? Languages.Bahasa : Languages.English)}
        onRequestDemo={() => setRequestDemo(!requestDemo)}
      />
      <section className="main-layout">
        {cloneElement(props.children, { 
          lang: locale === 'id' ? Languages.Bahasa : Languages.English,  
          requestDemo: requestDemo,
          onUpdateReqDemo: () => setRequestDemo(!requestDemo) 
        })}
      </section>
      <MainFooter lang={locale === 'id' ? Languages.Bahasa : Languages.English}/>
    </>
  );
}
