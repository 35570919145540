import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Languages } from "utils/datautils";
import styles from "../styles/FloatWhatsapp.module.css";
import strings from "@assets/strings/float_whatsapp.json";

type MainProps = {
  lang: Languages;
};

export default function FloatWhatsapp(props: MainProps) {
  const [waUrl, setWaUrl] = useState<string>("/wa");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isIconChange, setIsIconChange] = useState<boolean>(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [hasModalOpened, setHasModalOpened] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWaUrl(`/wa${window.location.search}`);
    }

    return () => { }

  }, [props]);

  const handleButttonClick = () => {
    setIsModalOpen(!isModalOpen);
    setIsIconChange(!isIconChange)
  }

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const pageHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;

    const scrollThreshold = window.innerWidth >= 900 ? 0.6 : 0.4;

    if (
      scrollPosition > lastScrollY && 
      scrollPosition + windowHeight >= pageHeight * scrollThreshold &&
      !hasModalOpened 
    ) {
      setIsModalOpen(true);
      setIsIconChange(true);
      setHasModalOpened(true);  
    }

    setLastScrollY(scrollPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);



  return (
    <div>
     <div className={styles.button_container} onClick={handleButttonClick}>
          <div className={styles.button_chat}></div>
          <div className={styles.chat_img}>
            {!isIconChange ? (
              <img src="/assets/ChatCircleDots.png"/>
            ) : (
              <img src="/assets/CaretCircleDown.png"/>
            )}
          </div>
    </div>
    {isModalOpen && (
      <div>
        <div
          className={styles.modalContent}
        >
          <div className={styles.modalHeader}>
            <img src="/assets/ask_lia.png" alt="" className={styles.modal_img} />
            <h2>{strings?.title[props.lang]}</h2>
          </div>
          <div className={styles.modalBody}>
            <p>{strings?.message[props.lang]}</p>
            <Link href={waUrl} locale={props.lang === 'id' ? 'id' : 'en'} passHref>
              <a target="_blank" rel="noopener noreferrer">
                <div className={styles.wa_click}>
                  <div className={styles.wa_desc}>
                      <img src="/assets/wa_logo.png" alt="Wa logo" className={styles.wa_img}/>
                      <p>{strings?.startChat[props.lang]}</p>
                  </div>
                  <img src="/assets/PaperPlaneRight.png" alt="" className={styles.send_img}/>
                </div>
              </a>
            </Link>
          </div>
        </div>
      </div>
    )}
    </div>
  );
}
