import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Languages } from "utils/datautils";
import navStr from "@assets/strings/navbar.json";
import footerStr from "@assets/strings/footer.json";
import styles from "../styles/Footer.module.css";

type MainProps = {
  lang: Languages;
};

export default function MainFooter(props: MainProps) {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const [language, setLanguage] = useState(props.lang);
  const rcsList = [
    // {
    //   href: "/pages/about",
    //   target: "_self",
    //   text: navStr.aboutRcs[language],
    //   onClick: () => {
    //     document.getElementById('explanation-section')?.scrollIntoView({
    //       behavior: 'smooth'
    //     })
    //     scrollNav()
    //   }
    // },
    // {
    //   href: "/pages/use-case",
    //   target: "_self",
    //   text: navStr.solutions[language],
    //   onClick: () => {
    //     document.getElementById('our-solution-section')?.scrollIntoView({
    //       behavior: 'smooth'
    //     })
    //     scrollNav()
    //   }
    // },
    // {
    //   href: "/pages/articles",
    //   target: "_self",
    //   text: navStr.features[language],
    //   onClick: () => {
    //     document.getElementById('features-section')?.scrollIntoView({
    //       behavior: 'smooth'
    //     })
    //     scrollNav()
    //   }
    // },
    {
      href: "/about",
      target: "_self",
      text: navStr.aboutRcs[props.lang],
    },
    {
      href: "/use-case",
      target: "_self",
      text: navStr.useCase[props.lang],
    },
    {
      href: "/insights",
      target: "_self",
      text: navStr.articles[props.lang],
    },
    {
      href: "/faq",
      target: "_self",
      text: navStr.faqs[props.lang],
    },
    {
      href: "/terms-and-conditions",
      target: "_self",
      text: navStr.toc[props.lang],
    },
  ];
  const listServices = [
    {
      href: "https://pro.hukumonline.com/",
      target: "_blank",
      text: footerStr.pro_hukumol[props.lang],
    },
    {
      href: "https://rcs.hukumonline.com/",
      target: "_blank",
      text: footerStr.rcs[props.lang],
    },
    {
      href: "https://exdoma.hukumonline.com/",
      target: "_blank",
      text: footerStr.exdoma_hukumol[props.lang],
    },
    {
      href: "https://www.hukumonline.com/pusatdata",
      target: "_blank",
      text: footerStr.data[props.lang],
    },
    {
      href: "https://www.hukumonline.com/berita/",
      target: "_blank",
      text: footerStr.berita[props.lang],
    },
    {
      href: "https://www.hukumonline.com/klinik",
      target: "_blank",
      text: footerStr.klinik[props.lang],
    },
    {
      href: "https://www.hukumonline.com/talks",
      target: "_blank",
      text: footerStr.event[props.lang],
    },
    {
      href: "https://www.hukumonline.com/stories",
      target: "_blank",
      text: footerStr.stories[props.lang],
    },
    {
      href: "https://jurnal.hukumonline.com/",
      target: "_blank",
      text: footerStr.jurnal[props.lang],
    },
    {
      href: "https://learning.hukumonline.com/",
      target: "_blank",
      text: footerStr.online_course[props.lang],
    },
    {
      href: "https://ranking.hukumonline.com/",
      target: "_blank",
      text: footerStr.ranking[props.lang],
    },
  ];

  useEffect(() => {
    setLanguage(props.lang);
  }, [props.lang]);

  const gotoTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const scrollNav = () => {
    setTimeout(() => {
      window.scrollBy({ top: -100, behavior: "smooth" });
    }, 650);
  };

  const openEmail = (email: string) => {
    window.open(`mailto:${email}?subject=Ask Hukumonline&body=`);
  };

  return (
    <div className={styles.footer}>
      <div className={`container ${styles.footer_box}`}>
        <img
          onClick={gotoTop}
          className={styles.hol_logo}
          alt="hukumonline logo"
          src="/assets/hukumonline_original.png"
        />
        <div className={styles.follow_us}>
          <div className={styles.section_title}>Follow Us</div>
          <div className={styles.hol_logos}>
            <a
              href="https://web.facebook.com/hukumonlinecom"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="hukumonline facebook" src="/assets/facebook.png" />
            </a>
            <a
              href="https://twitter.com/hukumonline/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="hukumonline twitter" src="/assets/twitter.png" />
            </a>
            <a
              href="https://www.instagram.com/hukum_online/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="hukumonline instagram" src="/assets/instagram.png" />
            </a>
            <a
              href="https://www.linkedin.com/company/hukumonline.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="hukumonline linkedin" src="/assets/linkedin.png" />
            </a>
            <a
              href="https://www.youtube.com/user/hukumonlinevideo"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="hukumonline youtube" src="/assets/youtube.png" />
            </a>
          </div>
        </div>
        <div className={`grid ${styles.hol_info}`}>
          <div className={styles.our_services}>
            <div className={styles.section_title}>Our Services</div>
            <div className={styles.services_list}>
              {listServices.map((ls, idx) => {
                return (
                  <div key={`services-${idx}`}>
                    <Link href={ls.href}>
                      <a target={ls.target || "_blank"}>{ls.text}</a>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.rcs}>
            <div className={styles.section_title}>
              Regulatory Compliance System
            </div>
            <div className={styles.services_list}>
              {rcsList.map((ls, idx) => {
                return (
                  <div key={`rcs-${idx}`}>
                    <Link href={ls.href}>
                      {/* <a onClick={ls.onClick} target={ls.target || '_blank'}>{ls.text}</a> */}
                      <a target={ls.target || "_blank"}>{ls.text}</a>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.hol_contact}>
          <div className="flex">
            <img src="/assets/building.png" alt="building"></img>
            <div>
              AD Premier 9th floor, Jl. TB Simatupang No.5 Ragunan, Pasar
              Minggu, Jakarta Selatan 12550, DKI Jakarta, Indonesia
            </div>
          </div>
          <div className="flex">
            <img src="/assets/phone.png" alt="phone"></img>
            <div>
              <div style={{ marginBottom: "4px" }}>
                Phone: +62 21 - 2270 - 8910
              </div>
              <div>Fax: +62 21 - 2270 - 8909</div>
            </div>
          </div>
          <div className="flex">
            <img src="/assets/message.png" alt="message"></img>
            <div className="flex-row">
              <div onClick={() => openEmail("demo@hukumonline.com")}>
                demo@hukumonline.com
              </div>
              <div
                className={styles.border_top}
                onClick={() => openEmail("marketing@hukumonline.com")}
              >
                marketing@hukumonline.com
              </div>
            </div>
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.section_title}>Group</div>
          <div className="flex">
            <a href="https://www.easybiz.id/" target="_blank" rel="noreferrer">
              <img src="/assets/easybiz.png" alt="Easybiz" />
            </a>
            <a href="https://www.justika.com/" target="_blank" rel="noreferrer">
              <img src="/assets/justika.png" alt="Justika" />
            </a>
          </div>
        </div>
        <div className={styles.certificate}>
          <div className={styles.section_title}>Certificate</div>
          <div className="flex">
            <a
              href="https://pse.kominfo.go.id/tdpse-detail/5020"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/kominfo.png" alt="Kominfo" />
            </a>
          </div>
        </div>
        <div className={`flex ${styles.copyright}`}>
          <img src="/assets/copyright.png" alt="copyright" />
          <div>{`${getCurrentYear()} Hak Cipta Terpelihara Hukumonline.com`}</div>
        </div>
      </div>
    </div>
  );
}
